var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"hover":"","busy":_vm.busy,"items":_vm.item.shipments,"fields":_vm.fieldsList,"responsive":"","sticky-header":'calc(100vh - 330px)'},scopedSlots:_vm._u([{key:"cell(sender)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{attrs:{"href":_vm.getCollectiveUrl(item.sender),"target":"_blank"}},[_c('div',{staticClass:"text-dark w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"150px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTranslatedName(item.sender))+" ")])])])]}},{key:"cell(receiver)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{attrs:{"href":_vm.getCollectiveUrl(item.receiver),"target":"_blank"}},[_c('div',{staticClass:"text-dark w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"150px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTranslatedName(item.receiver))+" ")])])])]}},{key:"cell(ref)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center"},[_c('p',[_vm._v(_vm._s(item.ref))])])]}},{key:"cell(freightmode)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"160px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getFreightMode(item.freightMode))+" ")])])]}},{key:"cell(incoterm)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"160px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getIncoterm(item.freightPrepaidIncoterm, item.freightCollectIncoterm))+" ")])])]}},{key:"cell(shippingdate)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"160px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTransactionDate(item.shippingDate))+" ")])])]}},{key:"cell(deliverydate)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"160px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTransactionDate(item.deliveryDate))+" ")])])]}},{key:"cell(user)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center pl-1 ml-25 mt-25",staticStyle:{"min-width":"150px"}},[[_c('b-link',{staticClass:"text-dark d-flex align-items-center",on:{"click":function($event){return _vm.fetchMembers(item.createdBy)}}},[_c('div',[(item.createdBy)?_c('user-avatar',{attrs:{"user":item.createdBy,"size":"24"}}):_c('user-avatar',{attrs:{"user":{},"size":"24"}})],1),_c('div',{staticClass:"ml-50"},[(item.createdBy)?_c('p',{staticClass:"font-weight-extrabold mb-0"},[_vm._v(" "+_vm._s(((item.createdBy.name) + " " + (item.createdBy.surname)))+" ")]):_c('span',{staticClass:"text-muted"},[_vm._v("---")])])])]],2)]}},{key:"cell(createdat)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"160px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getTransactionDate(item.createdAt))+" ")])])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[(item.status)?_c('b-badge',{staticClass:"text-capitalize mb-0 ml-1 mt-75 check-button",attrs:{"pill":"","variant":_vm.getColor(item.status)}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")]):_c('p',{staticClass:"text-muted mb-0 ml-2 pl-75 mt-75"},[_vm._v("---")])],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"text-primary ml-2 cursor-pointer",attrs:{"icon":"Edit2Icon","size":"18","disabled":""},on:{"click":function($event){return _vm.handleEdit(item)}}}),_c('b-modal',{attrs:{"id":"edit-shipment-form","title":_vm.$t('backoffice.shipments.edit'),"ok-title":_vm.$t('form.actions.save'),"ok-only":""},on:{"ok":function($event){return _vm.saveEditedTransaction()}},model:{value:(_vm.isModalEditOpened),callback:function ($$v) {_vm.isModalEditOpened=$$v},expression:"isModalEditOpened"}},[_c('div',[_vm._v("Coming soon... work in progress.")])]),_c('feather-icon',{staticClass:"text-primary cursor-pointer ml-1",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.handleDelete(item)}}}),_c('unshare-item-modal',{attrs:{"model-type":"shipment"},on:{"remove-item":_vm.handleRemoveItem,"modal-invisible":function($event){_vm.isDeleteModalVisible = false}},model:{value:(_vm.isDeleteModalVisible),callback:function ($$v) {_vm.isDeleteModalVisible=$$v},expression:"isDeleteModalVisible"}}),(item.fileURL)?_c('feather-icon',{staticClass:"text-primary cursor-pointer ml-1",attrs:{"icon":"DownloadIcon","size":"18","id":'download-payemnt-button-' + item.key},on:{"click":function($event){return _vm.handleDownloadFiles(item)}}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }